interface Tag extends Omit<Tags, 'id' | 'meta'> {}

export const useNewsStore = defineStore('news', () => {
  const news = ref<News[]>([])
  const tags = ref<Tag[]>([])
  const oneNews = ref<News>()
  const errors = ref<any>()
  const headers = { Authorization: '' }

  async function fetchNews(url: string) {
    const { data, error } = await useCustomFetch<NewsRequest>(url, { headers });

    const res: Res | any = data.value

    if(res) {
      news.value = res.items
    }
    if(error.value) {
      errors.value = error.value
    }
  }

  async function fetchNewsAndTags(urlNews: string, urlTags: string) {
    const [{ data, error }] =
      await Promise.all([
        useCustomFetch<TagRequest>( urlTags, { headers }),
        fetchNews(urlNews),
      ])

    const res: Res | any = data.value

    if (res)  {
      tags.value = []
      if(res.items) {
        res.items.forEach((tag: Tags) => {
          tags.value.push({ name: tag?.name })
        })
      }
    }
    if (error.value) {
      errors.value = error.value
    }
  }

  async function fetchOneNews(url: string) {
    const headers = { Authorization: ' ' }
    const {data, error} = await useApi<NewsRequest>(url, { headers });

    const res = data.value

    if(res) {
      oneNews.value = res.items[0]
    }
    if(error.value) {
      errors.value = error.value
    }
  }

  return { 
    news,
    tags,
    oneNews,
    fetchNews,
    fetchNewsAndTags,
    fetchOneNews
  }
})
